.splash-screen {
  height: 100vh;
  background-image: url("../../assets/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .card-img {
    width: 100%;
  }

  .splash-screen-hidden {
    opacity: 0;
    transition: 0.5s;
  }
  .splash-screen-container {
    background-color: transparent;
    margin: 0 auto;
    width: 600px;
    height: 600px;
    padding-top: calc(100vh / 2 - 300px);
    perspective: 1000px;
    @media only screen and (max-width: 700px) {
      width: calc(100vw - 50px);
      height: calc((100vw - 50px));
      padding-top: calc(100vh / 2 - (((100vw - 50px)) / 2));
    }

    .splash-screen-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;

      .splash-screen-front,
      .splash-screen-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      .splash-screen-overlay {
        position: absolute;
        right: 10px;
        bottom: 10px;
        z-index: 2;
        border-radius: 8px;
        transition: 1s;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .ui-button {
          padding: 12px 12px;
          @media only screen and (max-width: 768px) {
            padding: 8px 8px;
          }
          @media only screen and (max-width: 500px) {
            padding: 6px 6px;
          }
        }
        p {
          font-size: 18px;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
          @media only screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
      .splash-screen-overlay-show {
        opacity: 1;
      }
      .splash-screen-overlay-hidden {
        opacity: 0;
      }

      .splash-screen-front {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 20px 77px 0px rgba(0, 0, 0, 0.42);
        -webkit-box-shadow: 0px 20px 77px 0px rgba(0, 0, 0, 0.42);
        -moz-box-shadow: 0px 20px 77px 0px rgba(0, 0, 0, 0.42);
        cursor: pointer;
        .splash-screen-img {
          padding-top: 5%;
        }
      }

      .splash-screen-back {
        background-color: white;
        transform: rotateY(180deg);
      }
    }
  }
}
